<template lang="pug">
.sync-wrapper
  b-row
    b-col(md="6")
      b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('message.tableHeader.category') }}
              th {{ $t('message.tableHeader.detail') }}
            b-tr
              b-td {{ $t('message.tableHeader.connectionStatus') }}
              b-td
                span(:class="isOnline ? 'text-primary' : 'text-danger'")
                  | {{ isOnline ? "Online" : "Offline"}}
                b-avatar(
                  :variant="isOnline ? 'light-success' : 'light-danger'",
                  size="18",
                  class="ml-1"
                )
                  feather-icon(
                    size="18",
                    :icon="isOnline ? 'CheckCircleIcon' : 'XCircleIcon'",
                  )
            b-tr
              b-td {{ $t('message.tableHeader.lastSync') }}
              b-td {{ lastSync }}
  b-row
    b-col(md="6")
      b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('message.tableHeader.pendingToUpload') }}
              th {{ $t('message.tableHeader.quantity') }}
            b-tr
              b-td {{ $t('message.tableHeader.confirmations') }}
              b-td {{ qConfirmations }}
            b-tr
              b-td {{ $t('message.tableHeader.events') }}
              b-td {{ qEvents }}
            b-tr
              b-td {{ $t('message.tableHeader.dailyDialogues') }}
              b-td {{ qDialogues }}
            b-tr
              b-td {{ commitmentFunctionality ? $t('label.commitments') : $t('label.improvements') }}
              b-td {{ qImprovements }}

  b-button.mr-1(variant="primary" @click="uploadStorage('forced')" :disabled="forceDisabled")
    | {{ $t('forceSync') }}

  b-button(
    :variant="offlineMode ? 'primary' : 'warning'",
    :disabled="!isOnline && !offlineMode",
    @click="toggleOfflineMode()"
  )
    | {{ offlineMode ? $t('disableOfflineMode') : $t('forceOfflineMode') }}

</template>

<script>
import { ref, onMounted, onUnmounted, watch, computed } from "@vue/composition-api";
import { BTable, BTableSimple, BThead, BTr, BTd, BCard, BAvatar } from "bootstrap-vue";
import onlineUpdate from '@/views/habit/onlineUpdate'
import useNotifications from "@/composables/useNotifications";
import { getMany } from 'idb-keyval';
import store from '@/store'
import i18n from '@/libs/i18n'
import useCommon from "@/views/organization/useCommon";

export default {
  components: {
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    BAvatar,
  },
  setup() {
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { checkStorageData, updateStorageData } = useCommon()

    const isOnline = computed(() => store.state.app.isOnline)
    const offlineMode = computed(() => store.state.app.offlineMode)
    const lastSync = ref(localStorage.lastSync)

    const qDialogues = ref(0);
    const qEvents = ref(0);
    const qConfirmations = ref(0);
    const qImprovements = ref(0);

    const forceDisabled = ref(true)

    const { uploadStorage } = onlineUpdate()

    const commitmentFunctionality = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).commitment_functionality
      : false

    const toggleOfflineMode = async () => {
      store.commit('app/UPDATE_OFFLINE_MODE', !offlineMode.value)

      if (offlineMode.value) {
        store.commit('app/UPDATE_ONLINE_STATUS', false)
        showErrorMessage(i18n.t('message.offline_mode'))
        checkStorageData()
      } else if (navigator.onLine) {
        await uploadStorage()
        store.commit('app/UPDATE_ONLINE_STATUS', true)
        showSuccessMessage(i18n.t('message.online_mode'))
        updateStorageData()
      } else {
        showErrorMessage(i18n.t('message.still_offline_mode'))
      }
    }

    const fetchPending = () => {
      getMany(['dialoguesToUpload', 'eventsToUpload', 'confirmationsToUpload', 'improvementsToUpload'])
        .then(async ([dialogues, events, confirmations, improvements]) => {
          qDialogues.value = 0
          qEvents.value = 0
          qConfirmations.value = 0
          qImprovements.value = 0
          if (dialogues) qDialogues.value += dialogues.length
          if (events) qEvents.value += events.length
          if (confirmations) qConfirmations.value += confirmations.length
          if (improvements) qImprovements.value += improvements.length
          if (localStorage.dialogues) qDialogues.value += JSON.parse(localStorage.dialogues).length
          if (localStorage.eventsForSchedule) qEvents.value += JSON.parse(localStorage.eventsForSchedule).length
          if (localStorage.confirmations) qConfirmations.value += JSON.parse(localStorage.confirmations).length
          
          forceDisabled.value = qDialogues.value || qEvents.value || qConfirmations.value || qImprovements.value ? false : true
        })
    }

    onMounted(() => {
      fetchPending()
    })

    watch(isOnline, () => {
      if (isOnline.value && !offlineMode.value) {
        lastSync.value = localStorage.lastSync
        fetchPending()
      }
    })

    return {
      isOnline,
      lastSync,
      qDialogues,
      qEvents,
      qConfirmations,
      qImprovements,
      forceDisabled,
      uploadStorage,
      toggleOfflineMode,
      offlineMode,
      commitmentFunctionality
    };
  },
};
</script>
