import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import i18n from "@/libs/i18n"
import awsConnection from './aws'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import useCommonTodo from '@/views/apps/todo/useCommonTodo'
import useCommon from '@/views/organization/useCommon'
import { get, set, getMany, del } from 'idb-keyval'
import realmConnection from '@/views/habit/realm'

export default function onlineUpdate() {
  const userData = store.state?.userStore?.userData
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { singleUpload, sendEmail } = awsConnection()
  const { updateDailyDialogueWithKey } = useCommonDashboards()
  const { getEmailImprovementTemplate, addTask, formatEmailDate } = useCommonTodo()
  const { formatDatePicker, updateConfirmationWithKey, createImprovements } = useCommon()
  const { getItem, getItems, updateItem, createItem, createItems, ObjectId } = realmConnection()
  const { default_language, commitment_functionality } = JSON.parse(localStorage.getItem('clientData') || '{}')

  const postImprovement = (payload) => {
    return new Promise((resolve, reject) => {
      addTask(payload)
        .then(() => resolve())
        .catch(() => reject())
    })
  }

  const postDailyDialogue = async (payload) => {
    let dataFromPayload = {
      imgData: payload.imgData,
      improvementsNotMapped: payload.improvementsNotMapped
    }
    delete payload.imgData
    delete payload.improvementsNotMapped

    payload.client_id = ObjectId(payload.client_id)
    payload.created_by = ObjectId(payload.created_by)
    payload.location = ObjectId(payload.location)
    if (payload.employeeSurveys) payload.employeeSurveys = payload.employeeSurveys.map(e => ObjectId(e))
    
    try {
      // Create recognitions
      if (payload.recognitions?.length) {
        const recognitionsToCreate = payload.recognitions.map(e => ({
          ...e,
          client_id: ObjectId(e.client_id),
          worker: ObjectId(e.worker),
          motive: ObjectId(e.motive),
          created_by: ObjectId(e.created_by),
        }))

        const { insertedIds: newRecognitionIds } = await createItems({ collection: 'recognition', payload: recognitionsToCreate })
        payload.recognitions = newRecognitionIds
      }

      // Create improvements
      if (payload.improvements?.length) {
        const improvementsToCreate = payload.improvements.map(e => ({
          ...e,
          client_id: ObjectId(e.client_id),
          assignee: ObjectId(e.assignee),
        }))

        const { insertedIds: newImprovementIds } = await createItems({ collection: 'improvement', payload: improvementsToCreate })
        payload.improvements = newImprovementIds
      }

      const { insertedId } = await createItem({ collection: 'meeting', payload })
      if (!insertedId) throw new Error('Item not created')
      showSuccessMessage(i18n.t('message.daily_dialogue_created'))

      // Upload image to AWS and then update the daily dialogue in MongoDB with the AWS image key
      const dailyDialogueId = insertedId.toString()
      const { fileInfo, destinationFolder } = dataFromPayload.imgData || {}
      if (fileInfo) {
        singleUpload(fileInfo, destinationFolder)
          .then((key) => updateDailyDialogueWithKey(dailyDialogueId, key))
          .catch((err) => console.log(err))
      }

      // Send email to assignees with the details of the improvements opportunity created
      dataFromPayload.improvementsNotMapped?.forEach(e => {
        if (e.assignee?.email) {
          const subject = i18n.t('message.improvement_opportunity_assigned')
          let bodyData = {
            name: e.assignee?.name,
            title: e.note,
            tags: e.domain ? i18n.t(`domain.${e.domain}`) : '',
            creator: userData.fullName,
            dueDate: formatEmailDate(e.dueDate),
          }
          if (e.metadata?.length) {
            e.metadata.forEach(e => {
              if (e.name === "creation_date") e.answer = formatEmailDate(e.answer)
              Object.assign(bodyData, {[e.name]: e.answer})
            })
          }
          const body = getEmailImprovementTemplate(bodyData)
          
          sendEmail([e.assignee.email], subject, body)
            .then((response) => {
              if (response.MessageId) showSuccessMessage(i18n.t('message.email_send_improvement_success'))
            })
            .catch((err) => {
              console.log(err)
              showErrorMessage(i18n.t('message.email_send_improvement_error'))
            })
        }
      })
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.daily_dialogue_error'))
    }
  }
  
  const postEvent = async (payload, newImprovementIds) => {
    payload.client_id = ObjectId(payload.client_id)
    payload.process = ObjectId(payload.process)
    if (payload.attendee) payload.attendee = ObjectId(payload.attendee)
    payload.organizer = ObjectId(payload.organizer)
    payload.confirmation = payload.confirmation ? ObjectId(payload.confirmation) : null
    payload.improvements = payload.improvements?.map(e => ObjectId(e)) || []

    try {
      const { insertedId } = await createItem({ collection: 'event', payload })
      showSuccessMessage(i18n.t('message.confirmation_agenda'))

      // Update each improvement created with the origin_id from event
      if (insertedId) {
        newImprovementIds?.forEach(id => {
          try {
            const query = { _id: ObjectId(id) }
            const action = { $set: { origin_id: insertedId } }
      
            updateItem({ collection: 'improvement', query, action })
          } catch (error) {
            console.log(error)
            showErrorMessage(commitment_functionality ? i18n.t('message.commitment_update_error') : i18n.t('message.improvement_update_error'))
          }
        })
      }
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.no_confirmation_agenda'))
    }
  }

  const fetchEventConfirmation = async (id) => {
    try {
      const query = { _id: ObjectId(id) }
      const item = await getItem({ collection: 'event', query })
      if (!item) throw new Error('Item not found')
      return item.confirmation
    } catch (error) {
      console.log(error)
    }
  }

  const schedulleEvent = async (calendar, confirmationId, dataFromPayload) => {
    const startDateTime = formatDatePicker(dataFromPayload.eventDate)
    const endDateTime = formatDatePicker(dataFromPayload.eventDate, 13)

    if (confirmationId) {
      const firstDayOfCurrentMonth = new Date(startDateTime.getFullYear(), startDateTime.getMonth(), 1)
      const firstDayOfNextMonth = new Date(startDateTime.getFullYear(), startDateTime.getMonth() + 1, 1)
      
      const query = {
        'extendedProps.calendar': { $ne: "Realizadas" },
        start: { $gte: firstDayOfCurrentMonth, $lt: firstDayOfNextMonth },
        attendee: ObjectId(dataFromPayload.worker),
        process: ObjectId(dataFromPayload.processId)
      }

      try {
        const eventsToSearch = await getItems({ collection: 'event', query, options: { projection : { improvements: 1 }, sort: { start: 1 } } })
        
        if (eventsToSearch?.length) {
          const updateQuery = { _id: eventsToSearch[0]._id }
          const updatePayload = {
            extendedProps: { calendar: "Realizadas"},
            start: startDateTime,
            end: endDateTime,
            confirmation: ObjectId(confirmationId)
          }

          // If there are new improvements, create improvements and merge them with old improvements from the event if there are any
          if (dataFromPayload.improvements?.length) {
            try {
              const newImprovementIds = await createImprovements(dataFromPayload.improvements, "event", eventsToSearch[0]._id.toString())
              const oldImprovementIds = eventsToSearch[0].improvements?.map(e => e.toString()) || []
              const consolidatedImprovements = [...oldImprovementIds, ...newImprovementIds]
              updatePayload.improvements = consolidatedImprovements.map(e => ObjectId(e))
            } catch (error) {
              console.log(error)
            }
          }

          const action = { $set: updatePayload }

          await updateItem({ collection: 'event', query: updateQuery, action })
          return showSuccessMessage(i18n.t('message.Event_updated'))     
        }
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.event_update_error'))
      }
    }

    const payload = {
      client_id: userData.client.$oid,
      process: dataFromPayload.processId,
      attendee: dataFromPayload.worker || null,
      organizer: dataFromPayload.supervisor,
      start: startDateTime,
      end: endDateTime,
      confirmation: confirmationId || null,
      extendedProps: { calendar: calendar || 'Pendientes' },
    }

    let newImprovementIds = []
    if (dataFromPayload.improvements?.length) {
      try {
        newImprovementIds = await createImprovements(dataFromPayload.improvements, "event", null)
        if (newImprovementIds?.length) {
          payload.improvements = newImprovementIds
        }
      } catch (error) {
        console.log(error)
      }
    }
  
    postEvent(payload, newImprovementIds)
  }

  const updateEventWithConfirmation = async (confirmationId, dataFromPayload) => {
    if (!confirmationId || !dataFromPayload.eventId) {
      schedulleEvent('Realizadas', confirmationId, dataFromPayload)
      return
    }

    // Fetch the event to see if its confirmation has already been done by another user
    try {
      const eventConfirmation = await fetchEventConfirmation(dataFromPayload.eventId)
      if (eventConfirmation) {
        schedulleEvent('Realizadas', confirmationId, dataFromPayload)
        return
      }
    } catch (error) {
      showErrorMessage(i18n.t('message.event_fetch_error'))
    }

    const payload = {
      confirmation: ObjectId(confirmationId),
      process: ObjectId(dataFromPayload.processId),
      extendedProps: { calendar: 'Realizadas' },
      attendee: ObjectId(dataFromPayload.worker),
      start: formatDatePicker(dataFromPayload.eventDate),
      end: formatDatePicker(dataFromPayload.eventDate, 13),
    }

    const collection = 'event'
    const query = { _id: ObjectId(dataFromPayload.eventId) }

    // If there are new improvements, create improvements and get event to see if it already has improvements to merge them
    if (dataFromPayload.improvements?.length) {
      try {
        const newImprovementIds = await createImprovements(dataFromPayload.improvements, "event", dataFromPayload.eventId)
  
        const eventResponse = await getItem({ collection, query })
        if (!eventResponse) throw new Error('Item not found')

        const oldImprovementIds = eventResponse.improvements?.map(e => e.toString()) || []
        const consolidatedImprovements = [...oldImprovementIds, ...newImprovementIds]

        payload.improvements = consolidatedImprovements.map(e => ObjectId(e))
      } catch (error) {
        console.log(error)
      }
    }

    const action = { $set: payload }
  
    try {
      await updateItem({ collection, query, action })
      return showSuccessMessage(i18n.t('message.Event_updated'))     
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.event_update_error'))
    }
  }

  const postConfirmation = async (payload) => {
    const dataFromPayload = {
      processId: payload.process,
      worker: payload.worker,
      supervisor: payload.supervisor,
      eventDate: payload.date,
      imgData: payload.imgData,
      improvements: payload.improvements
    }

    delete payload.imgData
    delete payload.improvements

    if (payload.eventId) {
      dataFromPayload.eventId = payload.eventId
      delete payload.eventId
    }

    payload.client_id = ObjectId(payload.client_id)
    payload.process = ObjectId(payload.process)
    payload.worker = ObjectId(payload.worker)
    payload.supervisor = ObjectId(payload.supervisor)

    try {
      const { insertedId } = await createItem({ collection: 'confirmation', payload })
      if (!insertedId) throw new Error('Item not created')

      showSuccessMessage(i18n.t('message.confirmation_created'))
          
      const confirmationId = insertedId.toString()
      
      updateEventWithConfirmation(confirmationId, dataFromPayload)

      // Upload image to AWS and then update the confirmation in MongoDB with the AWS image key
      const { fileInfo, destinationFolder } = dataFromPayload.imgData || {}
      if (fileInfo) {
        singleUpload(fileInfo, destinationFolder)
          .then((key) => updateConfirmationWithKey(confirmationId, key))
          .catch((err) => console.log(err))
      }
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.confirmation_error'))
    }
  }

  const updateLastSync = () => {
    const date = new Date()
    const actualDate = date.toLocaleDateString(`${default_language || 'en'}-US`)
    const actualTime = date.toLocaleTimeString(`${default_language || 'en'}-US`)
    localStorage.lastSync = `${actualDate} ${actualTime}`
  }

  const upload = async ({ category, key, storage }) => {
    let itemsStored = storage === "indexedDB" ? await get(key) : JSON.parse(localStorage[key])
    let itemsNotUploaded = []
    let uploadFunction
    switch (category) {
      case "dialogues":
        uploadFunction = (item) => postDailyDialogue(item)
        break;
      case "events":
        uploadFunction = (item) => postEvent(item)
        break;
      case "confirmations":
        uploadFunction = (item) => postConfirmation(item)
        break;
      case "improvements":
        uploadFunction = (item) => postImprovement(item)
        break;
      default:
        return;
    }
    for (let i = 0; i < itemsStored.length; i++) {
      const item = itemsStored[i]
      try {
        await uploadFunction(item)
        updateLastSync()
      } catch (error) {
        console.log(error)
        itemsNotUploaded.push(item)
      }
    }
    if (itemsNotUploaded.length) {
      if (storage === "indexedDB") {
        try {
          await set(key, itemsNotUploaded)
        } catch (error) {
          console.log(error)
        }
      } else {
        localStorage[key] = JSON.stringify(itemsNotUploaded)
      }
    } else {
      if (storage === "indexedDB") await del(key)
      else localStorage.removeItem(key)
    }
  }

  const uploadStorage = async (origin) => {
    const [dialogues, events, confirmations, improvements] = await getMany(['dialoguesToUpload', 'eventsToUpload', 'confirmationsToUpload', 'improvementsToUpload'])

    if (dialogues) await upload({ category: "dialogues", key: "dialoguesToUpload", storage: "indexedDB" })
    else if (localStorage.dialogues) await upload({ category: "dialogues", key: "dialogues", storage: "localStorage" })

    if (events) await upload({ category: "events", key: "eventsToUpload", storage: "indexedDB" })
    else if (localStorage.eventsForSchedule) await upload({ category: "events", key: "eventsForSchedule", storage: "localStorage" })

    if (confirmations) await upload({ category: "confirmations", key: "confirmationsToUpload", storage: "indexedDB" })
    else if (localStorage.confirmations) await upload({ category: "confirmations", key: "confirmations", storage: "localStorage" })

    if (improvements) await upload({ category: "improvements", key: "improvementsToUpload", storage: "indexedDB" })

    if (origin !== "forced") updateLastSync()
  }

  return {
    uploadStorage
  }
}
